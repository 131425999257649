import React from 'react';
import * as styles from './index.module.scss';

export const FooterNavLinks = ({ data }) => {
    return (
        <nav
        className={styles.nav}
        >
            <ul
                className={styles.navList}
            >
                {
                    data && data.map((linkList, index) => {
                        return(
                            <li
                                key={`footer-nav-link-list-${index}`}
                            >
                                <p
                                    className={styles.listTitle}
                                >{linkList?.title}</p>
                                <ul 
                                    className={styles.navLinkList}
                                >
                                    {
                                        linkList?.links && linkList.links.map((link, index) => (
                                            <li key={`footer-nav-links-${index}`}>
                                                <a 
                                                    href={link.url} 
                                                    alt={link.text}
                                                >
                                                    {link?.text}
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    )
}