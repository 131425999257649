import React from 'react';
import * as styles from './index.module.scss';

export const FooterSocials = ({ data }) => {
    return (
        <ul className={styles.socialsList}>
            {data && data.map((social, index) => (
                <li key={`footer-socials-${index}`}>
                    <a 
                        href={social.url} 
                        alt={social.title} 
                        target="_blank"
                    >
                        <img 
                            src={social.image.url} 
                            alt={`${social?.title}`}
                        />
                    </a>
                </li>
            ))}
        </ul>
    )
}