import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ChevronIcon from '../svg-components/ChevronIcon';

import * as styles from './index.module.scss';

const TeamCard = ({
    image,
    name,
    telephoneNumber,
    role,
    areas,
    slug
}) => (
    <a 
        className={styles.TeamCard}
        href={`/asm-forms?${slug && slug}`}
        title={`Contact ${name}`}
    >
        <div className={styles.TeamCardImage}>
            {(typeof image !== 'undefined') ? <GatsbyImage image={getImage(image)} alt={image?.title} /> : null}
        </div>
        <div className={styles.TeamCardWrap}>
            {(name && name.length > 0) ? <p className={styles.name}>{name}</p> : null}
            {(areas && areas.length > 0) ? <p className={styles.region}><strong>Areas covered:</strong>  {areas}</p> : null}
            {(role && role.length > 0) ? <p className={styles.region}><strong>Role:</strong>  {role}</p> : null}
            {(telephoneNumber && telephoneNumber.length > 0) ? <p><strong>Tel:</strong>  {telephoneNumber}</p> : null}
        </div>
        <div className={styles.chevron}>
            <ChevronIcon />
        </div>
    </a>
)

export default TeamCard;