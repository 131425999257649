import React from "react";
import { HeaderSectionBottom } from "../header-section-bottom";
import { HeaderSectionTop } from "../header-section-top";

import logo from './../../../../images/Hamworthy-Logo.webp';
import MenuIcon from "../../../../lib/fontawesome/MenuIcon";

import * as styles from './index.module.scss';

export const HeaderSections = ({ data, menuState, setMenuState }) => {

  const {
    contentfulMenus
  } = data;

  return (
    <>
      <HeaderSectionTop />
      <div className={styles.wrapper}>
        <div className={styles.col}>
          <a href="/" className={styles.link}>
            <img height="42" width="196.25" className={styles.logo} src={logo} alt="Hamworthy Heating" />
          </a>
        </div>
        <div className={styles.col}>
          <HeaderSectionTop />
          <button
            className={styles.mobileMenuToggle}
            onClick={e => setMenuState(!menuState)}><MenuIcon /> Menu</button>
          <HeaderSectionBottom
            {...contentfulMenus}
            menuState={menuState}
            setMenuState={setMenuState} />
        </div>
      </div>
    </>
  )
}