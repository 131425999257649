import React, { useState, useEffect } from "react";

import * as styles from './index.module.scss';
import ArrowIcon from "../svg-components/ArrowIcon";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 600) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

    return (
        isVisible && 
        <button
            onClick={scrollToTop}
            className={styles.btnScrollTop}
        >
            <ArrowIcon title="Button to the top" />
        </button>
    );
};

export default ScrollToTop;