import React from 'react';
import * as styles from './index.module.scss';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const Partnerships = ({ data }) => {

    return (
        <div
            className={styles.partners}
        >
            <p
                className={styles.title} 
            >Partnerships</p>

            <div
                className={styles.logos} 
            >
                {data && data.map((image, index) => {
                    
                    if(image?.image?.gatsbyImageData !== null) {
                        return (
                            <GatsbyImage
                                alt={image?.image?.title}
                                className={styles.image} 
                                image={getImage(image?.image)} 
                                key={`partner-logo-${index}`}
                            />
                        )
                    }else{
                        return (
                            <img
                                alt={image?.image?.title}
                                className={styles.image}
                                height="70"
                                key={`partner-logo-${index}`}
                                src={image?.image?.url}
                                width="70"
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}