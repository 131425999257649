import React from "react";

import * as styles from './index.module.scss';

import MadeInBritain from './../../../../images/made-in-britain.png';
import Phone from './../../../../images/phone.svg';
import SearchBar from "../../../search/search";

export const HeaderSectionTop = () => {

  return (
    <div className={styles.topWrapper}>
      <div className={styles.madeInBritainWrapper}>
        <img src={MadeInBritain} alt="Made in Britain badge" />
      </div>
      <div className={styles.top}>
        <a className={`${styles.icon_link} ${styles.phone}`} href="tel:01202662500">
          <img className={`${styles.icon} ${styles.image}`} src={Phone} alt="" />
          01202 662500
        </a>
        <SearchBar />
      </div>
    </div>
  );
}