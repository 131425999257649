import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.scss'

import { FooterSections } from './lib/footer-sections';

export const Footer = () => {
  return(
    <footer className={styles.footer}>
      <StaticQuery
        query={graphql`
          query {
            contentfulFooter {
              socialLinks {
                title
                url
                image {
                  url
                }
              }
              companyLogo{
                url
              }
              partners {
                image{
                  gatsbyImageData(
                    width: 1024
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  url
                  title
                }
              }
              footerLinksList {
                title
                links {
                  url
                  text
                }
              }
            }
          }            
        `}
        render={data => <FooterSections data={data} />}
      />
    </footer>
  )
}