import React from 'react';
import { BannerCardContentLeft } from './lib/banner-card-content-left/index';
import { BannerCardContentRight } from './lib/banner-card-content-right/index';

import * as styles from './index.module.scss';

export const BannerCardContent = (props) => (
  <>
    <div id={`banner-block-left-col-${props?.id}`} className={styles.col + ' left-col'}>
      <BannerCardContentLeft {...props} />
    </div>
    { (props.template === 'product' || props.sideImage) ? (
      <div className={styles.col + ' right-col'} style={{alignItems: props.contentTextAlign}}>
        <BannerCardContentRight {...props} />
      </div> 
    ) : null }
  </>
);