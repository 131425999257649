import React from 'react';
import * as styles from './index.module.scss';

import { FooterSocials } from '../footer-socials/index';
import { FooterNavLinks } from '../footer-nav-links/index';
import { Partnerships } from '../partnerships/index';

export const FooterSections = ({ data }) => {

  const {
    contentfulFooter
  } = data;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.midWrapper}
      >
        <FooterNavLinks
          data={contentfulFooter?.footerLinksList}
        />
        <Partnerships
          data={contentfulFooter?.partners}
        />
      </div>

      <div
        className={styles.bottom}
      >
        <p className={styles.copywrite}>
          Hamworthy Heating {new Date().getFullYear()}. All Rights Reserved
        </p>
        <div
          className={styles.bottomRight}
        >
          <img
            alt=""
            className={styles.companyLogo}
            src={contentfulFooter?.companyLogo?.url}
          />
          <FooterSocials
            data={contentfulFooter?.socialLinks}
          />
        </div>
      </div>

    </div>
  )
}