import React, { useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

import * as styles from "./search.module.scss";
import SearchIcon from "./../../images/magnifying-glass.svg";

const SearchBar = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const queryString = params.get("query") || "";

    const [query, setQuery] = useState(queryString);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (query.trim())
            navigate(`/search?query=${encodeURIComponent(query)}`);
    };

    return (
        <div className={styles.searchBarWrapper}>
            <form className={styles.confirm} onSubmit={handleSearchSubmit}>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <button type="submit" className={styles.button}>
                    <img
                        className={styles.image}
                        src={SearchIcon}
                        alt="Search"
                    />
                </button>
            </form>
        </div>
    );
};

export default SearchBar;