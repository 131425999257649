import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { HeaderSections } from './lib/header-sections';

import * as styles from './index.module.scss';

export const Header = () => {
  const [menuState, setMenuState] = useState(false);

  return (
    <header 
      className={`${styles.header} ${menuState && styles.open}`}
    >
      <StaticQuery
        query={graphql`
          query { 
            contentfulMenus {
              menuItems {
                id
                template
                title
                url
                featuredBlock {
                  ...BannerCardFields
                }
                subMenu {
                  title
                  image {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                  description
                  id
                  url
                  featuredBlock {
                    ...BannerCardFields
                  }
                  subMenu {
                    title
                    description
                    id
                    url
                    description
                    featuredBlock {
                      ...BannerCardFields
                    }
                  }
                }
              }
            }
          }        
        `}
        render={data => <HeaderSections data={data} menuState={menuState} setMenuState={setMenuState} />}
      />
    </header>
  )
}