import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as genericStyles from './index.module.scss';

import TeamCard from '../../components/team';
import AppDownload from '../../components/app-download/index.module';

export const TextTypeChecker = (text, index,references) => {
    if (typeof text.nodeType !== 'undefined' && text.nodeType !== 'text') {
        return RichText({ raw: JSON.stringify({content: [text] }), references: references });
    } else if (typeof text.marks !== 'undefined' && text.marks.length > 0) {
        switch (text.marks[0].type) {
            case 'bold':
                return <strong key={`text-type-check-${index}`}>{text.value}</strong>;
            default:
                return text.value;
        }
    }

    return text.value;
}

export const parseTag = (tagAsText, contentToWrap, classes) => {
    switch (tagAsText) {
        case "h2":
            return <h2 className={classes}>{ contentToWrap }</h2>;
        case "h4":
            return <h4 className={classes}>{ contentToWrap }</h4>;
        case "h5":
            return <h5 className={classes}>{ contentToWrap }</h5>;
        case "h6":
            return <h6 className={classes}>{ contentToWrap }</h6>;
        case "span":
            return <span className={classes}>{ contentToWrap }</span>;
        case "h3":
        default:
            return <h3 className={classes}>{ contentToWrap }</h3>;
    }
}

const RichText = (contentRaw) => {
    if (!contentRaw || typeof contentRaw !== 'object' || typeof contentRaw.raw !== 'string')
        return null;

    let content = JSON.parse(contentRaw.raw);

    const {
        references
    } = contentRaw;

    return content.content.map((elData, index) => {
        let assetId,asset;

        //If there is no text, lets return null
        if ( ["unordered-list","ordered-list","list-item"].includes(elData.nodeType) ) {
            //Need some logic here for this, but not sure what yet
        } else if (elData.content.length === 1 && (typeof elData.content[0].value !== 'undefined' && elData.content[0].value.length === 0))
            return null;

        //Now we know we have data, let's actually create some elements
        switch (elData.nodeType) {
            case "hyperlink":
                return <a key={`hyperlink-item-content-${index}`} href={elData.data.uri}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</a>;
            case "blockquote":
                return <blockquote key={`blockquote-item-content-${index}`} >{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</blockquote>;
            case "hr":
                return <hr key={`hr-item-content-${index}`} />;
            case "list-item":
                return <li key={`list-item-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</li>;
            case 'unordered-list':
                return <ul key={`unordered-list-content-${index}`}>{ RichText({ raw: JSON.stringify({content: elData.content}), references: references }) }</ul>;
            case 'ordered-list':
                return <ol key={`unordered-list-content-${index}`}>{ RichText({ raw: JSON.stringify({content: elData.content}), references: references }) }</ol>;
            case 'paragraph':
                return <p key={`paragraph-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</p>;
            case 'heading-1':
                return <h1 key={`heading-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</h1>;
            case 'heading-2':
                return <h2 key={`heading-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</h2>;
            case 'heading-3':
                return <h3 key={`heading-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</h3>;
            case 'heading-4':
                return <h4 key={`heading-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</h4>;
            case 'heading-5':
                return <h5 key={`heading-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</h5>;
            case 'heading-6':
                return <h6 key={`heading-content-${index}`}>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</h6>;
            case 'table':
                return <div key={`table-${index}`} className={genericStyles.tableWrap}><table key={`heading-content-${index}`} className={genericStyles.table}><tbody>{ RichText({ raw: JSON.stringify({content: elData.content}), references: references }) }</tbody></table></div>;
            case 'table-row':
                return <tr key={`heading-content-${index}`}>{ RichText({ raw: JSON.stringify({content: elData.content}), references: references }) }</tr>;
            case "table-header-cell":
                return <th key={`heading-content-${index}`}>{ RichText({ raw: JSON.stringify({content: elData.content}), references: references }) }</th>;
            case 'table-cell':
                return <td key={`heading-content-${index}`}>{ RichText({ raw: JSON.stringify({content: elData.content}), references: references }) }</td>;
            case 'asset-hyperlink': 
                assetId = elData?.data?.target?.sys?.id;
                asset = references ? references.filter(ref => ref?.contentful_id === assetId) : [];
                if (asset.length > 0)
                    asset = asset[0];
                else
                    return null;
                return <a href={asset.url} target='_blank'>{elData.content.map((text, index) => TextTypeChecker(text, index,references))}</a>;
            case 'embedded-asset-block':
                assetId = elData?.data?.target?.sys?.id;
                asset = references ? references.filter(ref => ref?.contentful_id === assetId) : [];
                if (asset.length > 0)
                    asset = asset[0];
                else
                    return null;
                return (getImage(asset)) ? (
                    <GatsbyImage className={genericStyles.image} key={`embedded-asset-${assetId}`} image={getImage(asset)} objectFit='contain' alt='' />
                ) : <img key={`embedded-asset-${assetId}`}  src={asset.url} />
            case 'embedded-entry-block':
                let entryId = elData?.data?.target?.sys?.id;
                let entry = references ? references.filter(ref => ref?.contentful_id === entryId) : [];
                
                if (entry.length > 0)
                    entry = entry[0]; // We only want the first one (They would be duplicated otherwise and we don't want that)
                else
                    return null;

                if (entry?.teamMemberJobTitle && entry?.teamMemberName) 
                    return (
                        <TeamCard 
                            name={entry?.teamMemberName}
                            job={entry?.teamMemberJobTitle}
                            email={entry?.teamMemberEmailAddress}
                            contact={entry?.teamMemberContactNumber}
                            image={entry?.teamMemberImage}
                            region={entry?.teamMemberRegion}
                            {...entry} 
                        />
                    );
                else if (entry?.appleStoreDownloadLink && entry?.googlePlayStoreDownloadLink)
                    return (
                        <AppDownload {...entry} />
                    );
                else if (entry?.iframeCode?.iframeCode) 
                    return <div className={genericStyles.iframe} key={`embedded-entry-${entryId}`} dangerouslySetInnerHTML={{__html: entry.iframeCode.iframeCode}} />;
                else if (entry?.image && entry?.link && entry?.target)
                    return <a href={entry.link} target={entry?.target}><img src={entry?.image?.publicUrl} /></a>;
                else
                    return null;
        }

        return null;
    });
};

export default RichText;